<template>
  <div>
    <div class="row pb-3">
      <div class="col-md-9">
        <div class="row">
          <div
            class="col-md-4"
            v-if="this.logedInUser.roles[0].name === 'superadmin'"
          >
            <label for="" class="label-font">Company:</label>
            <b-form-group>
              <v-select
                @input="getJobs(SelectedCompany)"
                v-model="SelectedCompany"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companies"
                label="name"
                placeholder="Select Company"
                class="search-input bg-white"
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <label for="" class="label-font">Location:</label>
            <b-form-group>
              <b-form-input
                v-debounce:300ms="getJobs"
                v-model="query.location"
                type="text"
                placeholder="Search By Location"
                class="search-input bg-white"
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <label for="" class="label-font">Search by Job Title:</label>
            <b-form-group>
              <b-form-input
                v-debounce:300ms="getJobs"
                v-model="query.title"
                type="text"
                placeholder="Search By Job Title"
                class="search-input bg-white"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <router-link v-if="$can('create', 'Job Post')" to="/job-post/create">
          <b-button class="btn mt-1 mt-lg-0 add-btn d-block ml-auto">
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add New Job </span>
              <span>
                <img
                  src="@/assets/images/icons/add-icon.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
        </router-link>
      </div>
      <div class="w-100 mx-1">
        <div
          v-if="loading"
          class="text-center d-flex justify-content-center align-items-center loader"
        >
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <div v-else>
          <list-component
            v-if="jobs.length > 0"
            :jobs="jobs"
            @getJobs="getJobs"
          />
          <div v-else class="text-center">
            <h4 class="text-muted">No Jobs Found</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ListComponent from "./components/ListComponent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    ListComponent,
    BSpinner,
  },
  data() {
    return {
      jobs: [],
      companies: [],
      SelectedCompany: null,
      loading: false,
      logedInUser: JSON.parse(getLogedInUser()),
      query: {
        title: "",
        page: 1,
        per_page: 20,
        location: "",
        name: null,
      },
    };
  },
  created() {
    // this.getCompanies();
    this.getJobs();
  },
  methods: {
    // get all the companies
    // async getCompanies() {
    //   let res = await this.$store.dispatch("company/getCompanies");
    //   this.companies = res.data;
    //   if (this.logedInUser.roles[0].name === "admin") {
    //     let company = this.companies.find(
    //       (company) => company.id === this.logedInUser.id
    //     );
    //     this.companies = [company];
    //     this.SelectedCompany = company ? company : null;
    //     this.query.name = company.id;
    //     this.getJobs(this.SelectedCompany);
    //   }
    // },
    async getJobs(company) {
      this.loading = true;
      if (company) {
        this.query.name = company.id;
      } else {
        this.query.name = "";
      }
      let res = await this.$store.dispatch("job/getAppliedJobs", this.query);
      this.jobs = res.data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.detail-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text Additional */

  color: #79869f;
}

.btm-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;

  /* Text 2 */

  color: #a8adaf;
}

.btn-secondary {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.publish-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
  border-color: white !important;
}
.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

button.btn.preview.rounded-circle.btn-secondary.btn-sm {
  padding: 10px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
